.toolbar-bottom {
  text-align: center;
  margin-top: 20px;
  .toolbar {
    display: inline-block;
  }
}

.toolbar {
  margin-bottom: 8px;
  .sorter{
    .sort-by {
      float: left;
      margin-right: 15px;
      margin-bottom: 4px;
    }
    .sort-by a img {
      margin-top: -4px;
    }
    .limiter {
      float: right;
      #nbrpage {
        display: inline-block;
        border: 1px solid #ccc;
        .ais-hits-per-page-selector {
          border: none;
        }
      }
    }
    label {
      font-weight: 400;
      margin-right: 5px;
      color: #777;
      font-size: 13px;
      vertical-align: middle;
    }
    #ordreproduits {
      display: inline-block;
      border: 1px solid #ccc;
      .ais-sort-by-selector .ais-body .ais-sort-by-selector {
        background: #fff;
        border: none;
        padding: 2px 8px;
        height: 26px;
        color: #777;
        font-size: 13px;
      }
    }
    select {
      background: #fff;
      border: 1px solid #ccc;
      padding: 2px 8px;
      height: 26px;
      color: #777;
      font-size: 13px;
    }
    .view-mode {
      float: left;
      margin-right: 10px;
      line-height: 29px;
      height: 30px;
      i {
        font-size: 14px;
        border: 1px solid #ccc;
        background-color: #fff;
        color: #ccc;
        width: 26px;
        height: 26px;
        line-height: 24px;
        display: inline-block;
        text-align: center;
        vertical-align: top;
      }
      span i, .toolbar .sorter .view-mode span:hover i, .toolbar .sorter .view-mode span:focus i {
        color: #fff;
        background-color: #9e1c2f;
        border-color: #9e1c2f;
      }
      a:hover i, .toolbar .sorter .view-mode a:focus i {
        color: #fff;
        background-color: #9e1c2f;
        border-color: #9e1c2f;
      }
    }
    .pagination, .toolbar .sorter .ais-pagination {
      float: right;
      margin: 0 0 5px 8px;
    }
  }
  .sorter:after {
    content: '';
    display: table;
    clear: both;
  }
}

.toolbar .sorter .pagination a,
.toolbar .sorter .pagination span, .toolbar .sorter .ais-pagination a,
.toolbar .sorter .ais-pagination span {
  min-width: 26px;
  padding: 3px 6px;
  margin-left: 5px;
  text-align: center;
}

.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary:active.focus,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open > .dropdown-toggle.btn-primary.focus,
.open > .dropdown-toggle.btn-primary:focus,
.open > .dropdown-toggle.btn-primary:hover {
  color: #fff;
  background-color: #9e1c2f !important;
  border-color: #9e1c2f !important;
}

.btn-link,
a {
  color: #9e1c2f;
}
.btn-link:hover, .btn-link:focus,
a:hover,
a:focus {
  color: rgba(158, 28, 47, 0.8);
}

#header .header-nav-main nav {
  background-color: transparent;
}
#header .header-nav-main nav > ul > li > a.dropdown-toggle {
  -webkit-transition: none;
  -moz-transition: none;
  transition: none;
  color: #828385;
  padding: 13px 15px 13px 15px;
}
#header .header-nav-main nav > ul > li > a.dropdown-toggle:after {
  font-size: inherit;
  content: "\f107";
  right: 13px;
  top: 13px;
}
#header .header-nav-main nav > ul > li > a {
  -webkit-transition: none;
  -moz-transition: none;
  transition: none;
  color: #828385;
  padding: 13px 20px;
}
#header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-primary > a,
#header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-primary > a.dropdown-toggle {
  color: #fff;
}
#header .header-nav-main nav > ul > li:first-child > a,
#header .header-nav-main nav > ul > li:first-child > a.dropdown-toggle {
  padding-left: 0;
}
#header .header-nav-main nav > ul > li:hover > a,
#header .header-nav-main nav > ul > li:hover > a.dropdown-toggle, #header .header-nav-main nav > ul > li.open #header .header-nav-main nav > ul > li.dropdown:hover > a,
#header .header-nav-main nav > ul > li.open #header .header-nav-main nav > ul > li.dropdown:hover > a.dropdown-toggle, #header .header-nav-main nav > ul > li.dropdown.open > a,
#header .header-nav-main nav > ul > li.dropdown.open > a.dropdown-toggle {
  padding-bottom: 13px;
}
#header .header-nav-main nav > ul > li.dropdown .dropdown-menu {
  border-left: none;
  border-right: none;
  border-bottom: none;
  margin-top: 0;
}
#header .header-nav-main nav > ul > li.dropdown .dropdown-menu li a {
  border-bottom: none;
}
#header .header-nav-main nav > ul > li.dropdown .dropdown-menu li.dropdown-submenu > a:after {
  content: "\f105";
  font-family: FontAwesome;
  font-size: inherit;
  border: none;
  margin: 0;
}
#header .header-nav-main nav > ul > li.dropdown-mega > .dropdown-menu, #header .header-nav-main nav > ul > li.dropdown-mega-small > .dropdown-menu {
  border-radius: 0 0 4.16667px 4.16667px;
  padding: 0;
}
#header .header-nav-main nav > ul > li.dropdown-mega > .dropdown-menu .dropdown-mega-content, #header .header-nav-main nav > ul > li.dropdown-mega-small > .dropdown-menu .dropdown-mega-content {
  padding: 0;
  padding: 10px 20px 20px;
}
#header .header-nav-main nav > ul > li.dropdown-mega > .dropdown-menu .dropdown-mega-content ul,
#header .header-nav-main nav > ul > li.dropdown-mega > .dropdown-menu .dropdown-mega-content ol, #header .header-nav-main nav > ul > li.dropdown-mega-small > .dropdown-menu .dropdown-mega-content ul,
#header .header-nav-main nav > ul > li.dropdown-mega-small > .dropdown-menu .dropdown-mega-content ol {
  list-style: none;
  margin: 0;
  padding: 5px 0;
  left: 100%;
}
#header .header-nav-main nav > ul > li.dropdown-mega > .dropdown-menu .dropdown-mega-content ul li,
#header .header-nav-main nav > ul > li.dropdown-mega > .dropdown-menu .dropdown-mega-content ol li, #header .header-nav-main nav > ul > li.dropdown-mega-small > .dropdown-menu .dropdown-mega-content ul li,
#header .header-nav-main nav > ul > li.dropdown-mega-small > .dropdown-menu .dropdown-mega-content ol li {
  line-height: 22px;
}
#header .header-nav-main nav > ul > li.dropdown-mega > .dropdown-menu .dropdown-mega-content ul li a,
#header .header-nav-main nav > ul > li.dropdown-mega > .dropdown-menu .dropdown-mega-content ol li a, #header .header-nav-main nav > ul > li.dropdown-mega-small > .dropdown-menu .dropdown-mega-content ul li a,
#header .header-nav-main nav > ul > li.dropdown-mega-small > .dropdown-menu .dropdown-mega-content ol li a {
  padding: 0;
  margin: 0;
}
#header .header-nav-main nav > ul > li.dropdown-mega > .dropdown-menu .dropdown-mega-content ul li a:hover, #header .header-nav-main nav > ul > li.dropdown-mega > .dropdown-menu .dropdown-mega-content ul li a:focus,
#header .header-nav-main nav > ul > li.dropdown-mega > .dropdown-menu .dropdown-mega-content ol li a:hover,
#header .header-nav-main nav > ul > li.dropdown-mega > .dropdown-menu .dropdown-mega-content ol li a:focus, #header .header-nav-main nav > ul > li.dropdown-mega-small > .dropdown-menu .dropdown-mega-content ul li a:hover, #header .header-nav-main nav > ul > li.dropdown-mega-small > .dropdown-menu .dropdown-mega-content ul li a:focus,
#header .header-nav-main nav > ul > li.dropdown-mega-small > .dropdown-menu .dropdown-mega-content ol li a:hover,
#header .header-nav-main nav > ul > li.dropdown-mega-small > .dropdown-menu .dropdown-mega-content ol li a:focus {
  text-decoration: underline;
}
#header .header-nav-main nav > ul > li.dropdown-mega > .dropdown-menu .dropdown-mega-content ul li:hover a,
#header .header-nav-main nav > ul > li.dropdown-mega > .dropdown-menu .dropdown-mega-content ol li:hover a, #header .header-nav-main nav > ul > li.dropdown-mega-small > .dropdown-menu .dropdown-mega-content ul li:hover a,
#header .header-nav-main nav > ul > li.dropdown-mega-small > .dropdown-menu .dropdown-mega-content ol li:hover a {
  background-color: transparent;
}
#header .header-nav-main nav > ul > li.dropdown-mega > .dropdown-menu .dropdown-mega-content .dropdown-mega-sub-title, #header .header-nav-main nav > ul > li.dropdown-mega-small > .dropdown-menu .dropdown-mega-content .dropdown-mega-sub-title {
  display: block;
  font-size: 14px;
  font-weight: 600;
  padding: 0;
  text-transform: uppercase;
  line-height: 1.5;
  margin-top: 10px;
}
#header .header-nav-main nav > ul > li.dropdown-mega > .dropdown-menu .dropdown-mega-content .cat-img, #header .header-nav-main nav > ul > li.dropdown-mega-small > .dropdown-menu .dropdown-mega-content .cat-img {
  display: block;
  padding: 0;
  margin-top: 15px;
}
#header .header-nav-main nav > ul > li.dropdown-mega > .dropdown-menu .dropdown-mega-content .cat-img img, #header .header-nav-main nav > ul > li.dropdown-mega-small > .dropdown-menu .dropdown-mega-content .cat-img img {
  display: block;
  max-width: 100%;
  height: auto;
}
#header .header-nav-main nav > ul > li.dropdown-mega > .dropdown-menu .dropdown-mega-content .dropdown-mega-top, #header .header-nav-main nav > ul > li.dropdown-mega-small > .dropdown-menu .dropdown-mega-content .dropdown-mega-top {
  padding: 4px 0 8px;
  border-bottom: 1px solid #eee;
  color: #000;
}
#header .header-nav-main nav > ul > li.dropdown-mega > .dropdown-menu .dropdown-mega-content .dropdown-mega-top a,
#header .header-nav-main nav > ul > li.dropdown-mega > .dropdown-menu .dropdown-mega-content .dropdown-mega-top span, #header .header-nav-main nav > ul > li.dropdown-mega-small > .dropdown-menu .dropdown-mega-content .dropdown-mega-top a,
#header .header-nav-main nav > ul > li.dropdown-mega-small > .dropdown-menu .dropdown-mega-content .dropdown-mega-top span {
  font-size: 12px;
  font-weight: 400;
  color: #000;
  text-transform: uppercase;
  padding: 0;
  margin-right: 15px;
}
#header .header-nav-main nav > ul > li.dropdown-mega > .dropdown-menu .dropdown-mega-content .dropdown-mega-top span, #header .header-nav-main nav > ul > li.dropdown-mega-small > .dropdown-menu .dropdown-mega-content .dropdown-mega-top span {
  font-weight: 700;
}
#header .header-nav-main nav > ul > li.dropdown-mega > .dropdown-menu .dropdown-mega-content .menu-banner-area, #header .header-nav-main nav > ul > li.dropdown-mega-small > .dropdown-menu .dropdown-mega-content .menu-banner-area {
  position: relative;
  text-align: center;
}
#header .header-nav-main nav > ul > li.dropdown-mega > .dropdown-menu .dropdown-mega-content .menu-banner-area img, #header .header-nav-main nav > ul > li.dropdown-mega-small > .dropdown-menu .dropdown-mega-content .menu-banner-area img {
  display: inline-block;
  max-width: 100%;
  height: auto;
  margin: 20px auto 0;
}
#header .header-nav-main nav > ul > li.dropdown-mega > .dropdown-menu .dropdown-mega-content .menu-banner-area .menu-banner-header, #header .header-nav-main nav > ul > li.dropdown-mega-small > .dropdown-menu .dropdown-mega-content .menu-banner-area .menu-banner-header {
  position: absolute;
  top: -35px;
  left: -15px;
  text-align: left;
}
#header .header-nav-main nav > ul > li.dropdown-mega > .dropdown-menu .dropdown-mega-content .menu-banner-area .menu-banner-header h3, #header .header-nav-main nav > ul > li.dropdown-mega-small > .dropdown-menu .dropdown-mega-content .menu-banner-area .menu-banner-header h3 {
  font-size: 23px;
  font-weight: 600;
  color: #fff;
  background-color: #2e2e2e;
  line-height: 1;
  padding: 6px 50px 6px 8px;
  margin-bottom: 15px;
  text-transform: uppercase;
}
#header .header-nav-main nav > ul > li.dropdown-mega > .dropdown-menu .dropdown-mega-content .menu-banner-area .menu-banner-header .btn, #header .header-nav-main nav > ul > li.dropdown-mega-small > .dropdown-menu .dropdown-mega-content .menu-banner-area .menu-banner-header .btn {
  font-size: 13px;
  padding: 5px 7px 5px 8px;
  color: #fff;
  border: 0;
  font-size: 13px;
  min-width: 109px;
  text-align: center;
  text-transform: uppercase;
  border-radius: 3.33333px;
}
#header .header-nav-main nav > ul > li.dropdown-mega > .dropdown-menu .dropdown-mega-content .menu-banner-area .menu-banner-header .btn:hover, #header .header-nav-main nav > ul > li.dropdown-mega > .dropdown-menu .dropdown-mega-content .menu-banner-area .menu-banner-header .btn:focus, #header .header-nav-main nav > ul > li.dropdown-mega-small > .dropdown-menu .dropdown-mega-content .menu-banner-area .menu-banner-header .btn:hover, #header .header-nav-main nav > ul > li.dropdown-mega-small > .dropdown-menu .dropdown-mega-content .menu-banner-area .menu-banner-header .btn:focus {
  opacity: 0.9;
}
#header .header-nav-main nav > ul > li.dropdown-mega > .dropdown-menu .dropdown-mega-content .menu-banner-area .menu-banner-header .btn i, #header .header-nav-main nav > ul > li.dropdown-mega-small > .dropdown-menu .dropdown-mega-content .menu-banner-area .menu-banner-header .btn i {
  margin-left: 4px;
}
#header .header-nav-main nav > ul > li.dropdown-mega > .dropdown-menu .dropdown-mega-content .menu-banner-area p, #header .header-nav-main nav > ul > li.dropdown-mega-small > .dropdown-menu .dropdown-mega-content .menu-banner-area p {
  position: absolute;
  bottom: 8px;
  width: 60%;
  text-align: center;
  left: 50px;
  line-height: 14px;
  font-size: 13px;
  margin-bottom: 0;
}
#header .header-nav-main nav > ul > li.dropdown-mega-small > .dropdown-menu {
  width: 600px;
}
#header .header-nav-main nav > ul > li.dropdown-mega-small .mega-banner-bg img {
  position: absolute;
  right: 10px;
  top: -10px;
  height: 273px;
  width: auto;
  max-width: none;
  z-index: -1;
  border-radius: 6.65px;
}
#header .header-nav-main nav > ul > li.dropdown-mega > .dropdown-menu .dropdown-mega-content ul li,
#header .header-nav-main nav > ul > li.dropdown-mega > .dropdown-menu .dropdown-mega-content ol li {
  line-height: 23px;
}
#header .header-nav-main nav > ul > li:hover > a, #header .header-nav-main nav > ul > li.open > a, #header .header-nav-main nav > ul > li.active > a, #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-primary:hover > a, #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-primary.open > a, #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-primary.active > a {
  color: #ffffff !important;
  background-color: rgba(0, 0, 0, 0.2) !important;
}
#header .header-nav-main nav > ul > li:hover > a:hover, #header .header-nav-main nav > ul > li:hover > a:focus, #header .header-nav-main nav > ul > li.open > a:hover, #header .header-nav-main nav > ul > li.open > a:focus, #header .header-nav-main nav > ul > li.active > a:hover, #header .header-nav-main nav > ul > li.active > a:focus, #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-primary:hover > a:hover, #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-primary:hover > a:focus, #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-primary.open > a:hover, #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-primary.open > a:focus, #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-primary.active > a:hover, #header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-primary.active > a:focus {
  color: #ffffff !important;
  background-color: rgba(0, 0, 0, 0.2) !important;
}
#header .cart-dropdown .cart-dropdown-icon .cart-info {
  position: absolute;
  top: 50%;
  margin-top: -4px;
  left: 0;
  padding: 0;
  display: block;
  background: #9e1c2f;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  border-radius: 50%;
}
#header .custom-block a {
  text-transform: uppercase;
  color: #9e1c2f;
}
#header .header-nav-main nav {
  background-color: #9e1c2f;
}
#header .header-nav-main nav > ul > li:hover > a,
#header .header-nav-main nav > ul > li.open > a,
#header .header-nav-main nav > ul > li.active > a,
#header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-primary:hover > a,
#header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-primary.open > a,
#header .header-nav-main nav > ul > li.dropdown-full-color.dropdown-primary.active > a {
  background-color: #fff;
  color: #9e1c2f;
}
#header .header-nav-main nav > ul > li > a.dropdown-toggle {
  -webkit-transition: none;
  -moz-transition: none;
  transition: none;
  color: #fff;
  padding: 13px 15px 13px 15px;
}
#header .header-nav-main nav > ul > li > a.dropdown-toggle::after {
  margin-left: 5px;
  vertical-align: 0;
}
#header .header-search .header-search-wrapper .btn.btn-default {
  border-left: solid 1px #cccccc;
}
#header .header-nav-main nav > ul > li:first-child > a,
#header .header-nav-main nav > ul > li:first-child > a.dropdown-toggle {
  padding-left: 20px;
}
#header .header-column.header-column-center .header-logo {
  padding: 0px;
  margin-top: -45px;
}

/*# sourceMappingURL=style-bm.css.map */

/* Intervention juin 2021 */
.bg-red {background-color: #9e1c2f!important;}
.hide_arrow .tparrows {display: none!important;}
.tenant-name { color: #9e1c2f; font-size: 2.5rem; font-weight: 600;}

@media screen and (min-width: 1200px) {
  .container {
    width: 1440px !important;
  }
}

@media screen and (max-width: 768px) {
  #header .header-top {
    text-align: center !important;
  }
  .tenant-name {display: block; padding-top: 0.5rem; padding-bottom: 1rem;}
  .top-menu-area {width: 100%; padding-bottom: 1rem;}
  .top-menu-area span, .top-menu-area a {color: #777!important;}

  .menu-toggle-btn {font-size: 30px;}
  #header .header-search .search-toggle {font-size: 20px;}

  #header .header-top .header-search{ margin-top: 5.5px; margin-right: 50px;}
  .header-logo { margin-top: -20px!important;}
  .header-logo img {max-width: 100%!important;}
}

a.btn.btn-primary {
  color: #FFFFFF;
}

.align-right {
  text-align: right;
}
.align-left {
  text-align: left;
}

.js .slider-single > div:nth-child(1n+2) {
  display: none;
}

.js .slider-single.slick-initialized > div:nth-child(1n+2) {
  display: block;
}

.slider-nav .slick-slide {
  cursor: pointer;
}

.slhaut {
  min-height: 50px;
  width: 100%;
  overflow: hidden;
  margin: 0 auto 10px auto;
}

.slhaut img {
  width: 100%;
}

.slhaut iframe {
  width: 100%;
  height: 100%;
}

.slbas {
  max-height: 100px;
  min-height: 100px;
  width: 100%;
  overflow: hidden;
  margin: 0 auto 0 auto;
}

.slbas img {
  width: 75%;
  margin: auto;
}

input[type="checkbox"] {
  accent-color: #9e1c2f;
}